import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Form, Container, Row, Col, Image } from 'react-bootstrap'
import ProductApi from '../ProductApi';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function ProductDetail(props) {

  const [data, setData] = useState([])
  const [stockQty, setStockQty] = useState()
  const [products, setProducts] = useState([])
  const [search, setS] = useState('');

  const [loading, setLoading] = React.useState(false);

  var URL = localStorage.getItem('URL');
  var token = localStorage.getItem('token');
  const api = new ProductApi();



  const { id } = useParams();

  useEffect(() => {
    api.getProductDetail({ URL, token, id })
      .then(response => {

        if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

        }
        else {
          localStorage.setItem('productDetail', JSON.stringify(response.data));
          setProducts(response.data.items);
          console.log('RESPONSE DATA ' + response.data['extension_attributes'].stock_item.qty);
          setData(response.data);
          setStockQty(response.data['extension_attributes'].stock_item.qty);
          setLoading(true);
        }
      })
      .catch(err => {
        setData(JSON.parse(localStorage.getItem('productDetail')));


      })
    // eslint-disable-next-line
  }, [])

  if (!loading) {
    return ("Loading... Please wait a few seconds")
}

  function convertDate(date){
    if(date !== undefined){
        const convertedDate = new Date(date).toISOString().split('T')[0];
        return convertedDate;
    }
    return date;
}

  return (
    <div style={{ backgroundColor: "#D6E6E6" }}>
      <br />
      <h4>Product details</h4>
      <hr color="#E4A302"></hr>
      <Container fluid style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <Row >
          <Col style={{ color: "#009199", textAlign: 'left' }}>Sku</Col>
          <Col>{data.sku}</Col>
        </Row>
        <br />
        <Row >
          <Col style={{ color: "#009199", textAlign: 'left' }}>Name</Col>
          <Col>{data.name}</Col>
        </Row>
        <br />
        <Row>
          <Col style={{ color: "#009199", textAlign: 'left' }}>Price</Col>
          <Col>€{parseFloat(data.price).toFixed(2)}</Col>
        </Row>
        <br />
        <Row>
          <Col style={{ color: "#009199", textAlign: 'left' }}>In stock</Col>
          <Col>{stockQty}</Col>
        </Row>
        <br />
        <Row>
          <Col style={{ color: "#009199", textAlign: 'left' }}>Date created</Col>
          <Col>{data.updated_at}</Col>
        </Row>
        <br />

      </Container>
    </div>
  )
}   