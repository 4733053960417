import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Image, Form, Button } from 'react-bootstrap'
import ProductApi from '../ProductApi';
import { Link } from 'react-router-dom';
import { Accordion, Card, InputGroup } from 'react-bootstrap'

import Pagination from '../../Components/Pagination';

export default function Products() {
    const [search, setS] = useState('');
    const [data, setData] = useState([])

    var URL = localStorage.getItem('URL');
    var token = localStorage.getItem('token');
    const [loading, setLoading] = React.useState(false);
    const api = new ProductApi();
    const [sku, setSKU] = useState([])

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);

    function updateInput(e) {

        e.preventDefault();
        if (!search) {
            setData(JSON.parse(localStorage.getItem('products')));
            return true;
        }
        api.getSearchedProductByName({ URL, token, search })
            .then(response => {
                setData(response.data);
            })
            .catch(err => {
                setData(JSON.parse(localStorage.getItem('products')));
            })

    }

    useEffect(() => {

        api.getProductslist({ URL, token })
            .then(response => {

                if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

                }
                else {
                    localStorage.setItem('products', JSON.stringify(response.data));
                    setData(response.data);
                    setLoading(true);
                }
            })
            .catch(err => {
                setData(JSON.parse(localStorage.getItem('products')));
                setLoading(true);
            })
        // eslint-disable-next-line
    }, [])
    if (!loading) {
        return ("Loading... Please wait a few seconds")
    }


    // get pagination
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = data.slice(indexOfFirstData, indexOfLastData);




    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div>
            <br />
            <InputGroup className="mb-3">

                <Form.Control

                    value={search}

                    type="text"
                    placeholder="Search product by name"
                    onChange={(e) => setS(e.target.value)}
                />
                <InputGroup.Append>
                    <Button variant="btn btn-success" onClick={updateInput}>Search</Button>
                </InputGroup.Append>
            </InputGroup>

            <h3>Products</h3>

            <table class="table table-striped">
                <thead>
                    <tr>
                        {/* <th>id</th> */}
                        <th>Sku</th>
                        {/* <th>picture</th> */}
                        <th>Name</th>
                        <th>Price</th>
                        {/* <th>stock</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        currentData.map((item) =>
                            <tr key={item}>
                                {/* <td key="{item.id}">{item.id}</td> */}
                                <td key="{item.sku}">{item.sku}</td>
                                {/* <td key="{item.picture}">
                                    <Image src={ URL + '/pub/media/catalog/product' + item.media_gallery_entries[0].file} rounded responsive />
                                </td> */}
                                <td key="{item.name}">{item.name}</td>
                                <td key="{item.price}">€{parseFloat(item.price).toFixed(2)}</td>
                                {/* <td key="{item.id}">{item.stock.qty}</td> */}
                                <td key="{item.length + item.id }">
                                    <Link to={`/products/detail/${item.sku}`} variant="primary">View more</Link></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <br />
            <Pagination
                dataPerPage={dataPerPage}
                totalData={data.length}
                paginate={paginate}
            />
        </div>
    )
}
