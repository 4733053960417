import Api from "../Reusabilities/Api";

export default class LoginApi extends Api{
 
  getToken = (username, password, URL) => {
    return this.init().post('/login', {username, password, URL})
    .catch(function (error) {
      if (error.response) {
          return {data:
            {
            message:'There went something wrong with the system. Please, come back later.'
          }
        };   // `error.response.data` here - NOTE difference
      }
    });
  }
}