import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { AlertCustomize } from '../../Reusabilities/AlertCustomize';

import CustomerApi from "../CustomerApi";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [firstname, setF] = useState("");
  const [lastname, setL] = useState("");
  const [ setB] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(firstname);
    var api = new CustomerApi();

    var URL = localStorage.getItem('URL');
    var token = localStorage.getItem('token');

    api.addCustomer({
      URL,
      token,
      firstname,
      lastname,
      email,
      password

    })
      .then(response => {
        if(!response.data.hasOwnProperty('message'))
        {
          setB( <AlertCustomize variant='danger' message={'Successful created!'} />);
          alert('Successful created!');
          
        }
        else
        {
          setB( <AlertCustomize variant='danger' message={response.data.message} />);}
      })
      .catch(err => {
        console.log('no customer found');
      })

  };

  return (
    
  <Form onSubmit={onSubmit}>
      <Form.Group controlId="formFirstname">
        <Form.Label>First name</Form.Label>
        <Form.Control
    
    type="text"
          placeholder="Enter first name"
          value={firstname}
          onChange={(e) => setF(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formLastname">
        <Form.Label>Last name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter last name"
          value={lastname}
          onChange={(e) => setL(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="text"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit" block>
        Save a new Customer
      </Button>
    </Form>
  );

};

export default function Display() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>

      <Button variant="primary" block size="lg" onClick={handleShow}>
        Add a new customer
        </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new customer</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <LoginForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close Modal
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}