import React from 'react'
import Alert  from 'react-bootstrap/Alert'

const OfflineStatus = () => {
    return (
            !navigator.onLine ?
                <Alert variant='dark'>
                    you are in offline mode. Please check the internet connection.
                </Alert>
                : null
    )
}

export default OfflineStatus
