import React, { useState } from 'react';
import { AlertCustomize } from '../../Reusabilities/AlertCustomize';
import LoginApi from "../ApiLogin";
import Logo from './3webappslogo.jpg'
import logodrawdatamen from './undraw_All_the_data_re_hh4w.jpg'
import undraw_online_groceries_a02y from './undraw_online_groceries_a02y.jpg'

import { Notyf } from "notyf";
import "notyf/notyf.min.css";

import DashbordApi from '../DashBordApi';

//import logo from './logo.svg';
import { Form, Button } from 'react-bootstrap'


//future use
//axios.defaults.withCredentials = true;

export default function Login() {
    const [URL, setURL] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [SA, setSA] = useState(false);
    const [mes, setMessage] = useState('');

    const dashBordApi = new DashbordApi();

    const api = new LoginApi();

    // source: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
    function isValidURL(string) {
        var res = string.match(/(https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g);
        return (res !== null)
    };
    function validateForm() {
        if (!isValidURL(URL)) return
        return URL.length > 0 && username.length > 0 && password.length > 0 && isValidURL(URL);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        var response = await api.getToken(username, password, URL);
        if (response.data === '500') {
            setSA(true);
            setMessage('There went something wrong, try again later.');
        }
        else if (response.data.hasOwnProperty('message')) {
            setSA(true);
            setMessage(response.data.message);
        }
        else if (response.data.length === 32) {

            localStorage.setItem('URL', URL);
            localStorage.setItem('timestamp', new Date().getTime());
            localStorage.setItem('token', response.data);
            //give data to serviceworker
        const channel4Broadcast = new BroadcastChannel('URL');
        channel4Broadcast.postMessage({
            key: URL, token: response.data,
            lastorderDate: localStorage.getItem('lastorderDate'),
            magentoStatus: localStorage.getItem('magentoOfflineNotification'),
            oneOrder: localStorage.getItem('receiveOneOrderNotification'),
            //multipleOrders: localStorage.getItem('receiveMultipleOrderNotification'),
            //timeNotificationMultipleOrders: localStorage.getItem('timeNotificationMultipleOrders'),
            dailyRevenue: localStorage.getItem('receiveDailyRevenueNotification'),
            dailyRevenueTime: localStorage.getItem('timeNotificationDailyRevenue')

        });

            const date = new Date().getTime();
            window.location.reload(false);
        }
        else {

            setSA(true);
            if (response.data.hasOwnProperty('message')) setMessage(response.data.message);
            else setMessage('There went something wrong, please try again later or contact with us.');
        }
    }

    return (
        <div>
            <img id="logocenter" src={Logo} alt="ss" />
            <h2 id="cd3">3commerce dashboard</h2>

            <body>

                <img id="logodrawdatamen" src={logodrawdatamen} alt="ss" align="left" />
                <img id="undraw_online_groceries_a02y" src={undraw_online_groceries_a02y} alt="ss" align="right" />
            </body>

            <br />   <br />   <br />   <br />   <br />




            <h1 id="wsi">Welcome, please sign in</h1>
            {SA ? <AlertCustomize variant='danger' message={mes} /> : ''}
            <div className="Login">
                <Form onSubmit={handleSubmit}>
                    <Form.Group size="lg" controlId="URL">
                        <Form.Label>Your webshop domain URL</Form.Label>
                        <Form.Control
                            autoFocus
                            type="text"
                            value={URL}
                            onChange={(e) => setURL(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Button id="loginbutton" block size="lg" type="submit" disabled={!validateForm()}>
                        Login
                    </Button>

                </Form>
                <Button variant="outlined" block size="lg" type="submit" disabled={!validateForm()}>
                    Help
                </Button>
            </div>
        </div>
    )
}
