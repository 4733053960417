import * as axios from "axios";
import Api from "../Reusabilities/Api";

export default class ProductApi extends Api {


  init = () => {

    let headers = {
      Accept: "application/json",
    };

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 28000,
      headers: headers,
    });

    return this.client;
  };

  getProductslist = async ({ URL, token }) => {
    return await this.init().post('/products', {
      URL, token
    })
  };

  getSearchedProductByName = async ({ URL, token, search }) => {
    console.log('CALLED ' + search)
    return await this.init().post('/products/name/' + search, {
      URL, token
    })
  };

  getProductDetail = async ({ URL, token, id }) => {
    console.log('CALLED ' + id);
    return await this.init().post('/products/' + id, {
      URL, token
    })
  };

  getStocklist = async ({ URL, token, sku }) => {
    return await this.init().post('/products/stock/' + sku, {
      URL, token
    })
  };

  searchProduct = async ({ URL, token, search }) => {
    return await this.init().post('/productSearch', {
      URL, token, search
    })
  };

}