import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Form, Container, Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import OrderApi from '../OrderApi';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';


export default function OrderDetail(props) {

    const [data, setData] = useState([])
    const [orders, setOrders] = useState([])
    const [search, setS] = useState('');
    const [loading, setLoading] = React.useState(false);

    var URL = localStorage.getItem('URL');
    var token = localStorage.getItem('token');
    const api = new OrderApi();


    const history = useHistory();


    const { id } = useParams();

    useEffect(() => {

        api.getOrderDetail({ URL, token, id })
            .then(response => {

                if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

                }
                else {
                    localStorage.setItem('orderDetail', JSON.stringify(response.data));
                    setOrders(response.data.items);
                    setData(response.data);
                    setLoading(true);
                }
            })
            .catch(err => {
                setData(JSON.parse(localStorage.getItem('orderDetail')));


            })
        // eslint-disable-next-line.

    }, [])

    if (!loading) {
        return ("Loading... Please wait a few seconds")
    }

    function convertDate(date) {
        if (date !== undefined) {
            const convertedDate = new Date(date).toISOString().split('T')[0];
            return convertedDate;
        }
        return date;
    }

    function mainWebsite(store_name) {
        if (store_name !== undefined) {
            const main_website = store_name.split("\n")
            return main_website[0];
        }
        return store_name;
    }

    function store(store_name) {
        if (store_name !== undefined) {
            const store = store_name.split("\n")
            console.log(store);
            return store[1];
        }
        return store_name;
    }

    function storeView(store_name) {
        if (store_name !== undefined) {
            const store_view = store_name.split("\n")
            return store_view[2];
        }
        return store_name;
    }


    return (
        <div style={{ backgroundColor: "#D6E6E6" }}>
            <br />
            <h4>Order details</h4>
            <hr color="#E4A302"></hr>
            <br />

            <Container fluid style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <Row >
                    <Col style={{ color: "#009199", textAlign: 'left' }}>Order number</Col>
                    <Col >{data.increment_id}</Col>
                </Row>
                <br />
                <Row >
                    <Col style={{ color: "#009199", textAlign: 'left' }}>Email</Col>
                    <Col >{data.customer_email}</Col>
                </Row>
                <br />
                <Row >
                    <Col style={{ color: "#009199", textAlign: 'left' }}>Purchase date</Col>
                    <Col>{data.created_at}</Col>
                </Row>
                <br />
                <Row >
                    <Col style={{ color: "#009199", textAlign: 'left' }}>Grand Total</Col>
                    <Col>€{parseFloat(data.base_grand_total).toFixed(2)}</Col>
                </Row>
                <br />
                <Row>
                    <Col style={{ color: "#009199", textAlign: 'left' }}>Status</Col>
                    <Col>{data.status}</Col>
                </Row>
                <br />
                <Row>
                    <Col style={{ color: "#009199", textAlign: 'left' }}>Number of items</Col>
                    <Col>{orders.length}</Col>
                </Row>
                <br />
                <Row>
                    <Col style={{ color: "#009199", textAlign: 'left' }}>Website</Col>
                    <Col>{mainWebsite(data.store_name)}</Col>
                </Row>
                <br />
                <Row>
                    <Col style={{ color: "#009199", textAlign: 'left' }}>Store</Col>
                    <Col>{store(data.store_name)}</Col>
                </Row>
                <br />
                <Row>
                    <Col style={{ color: "#009199", textAlign: 'left' }}>Store View</Col>
                    <Col>{storeView(data.store_name)}</Col>
                </Row>
            </Container>
            <br />
            <br />
            {/* <Table responsive="sm"> */}
            {/* <thead> */}
            {/* <tr> */}

            {/* <th>ID</th>
                        <th>Email</th>
                        <th>Date</th>
                        <th>Grand total</th>
                        <th>Status</th>
                        <th></th> */}
            {/* </tr> */}
            {/* </thead> */}
            {/* <tbody> */}
            {/* { */}

            {/* <tr key="{data.entity_id}">
                                <td key="{data.entity_id}">{data.entity_id}</td>
                                <td key="{data.customer_email}">{data.customer_email}</td>
                                <td key="{data.updated_at}">{data.updated_at}</td>
                                <td key="{data.base_grand_total}">{data.base_grand_total}</td>
                                <td key="{data.status}">{data.status}</td>
                            </tr> */}

            {/* } */}
            {/* </tbody> */}
            {/* </Table> */}
            {/* <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Search on order id" />
                <br />
                <button style={{float: 'left'}} type="button" class="btn btn-primary">Search</button>
            </Form.Group>
            <br />
            <br />
            <h2 style={{float: 'left'}} class="text-info">These are the items in your order</h2>
            <br /> */}
            <h7>These are the products in your order</h7>
            <br />
            <hr color="#E4A302"></hr>
            <table class="table table-striped">
                <thead>
                    <tr>
                        {/* <th>id</th> */}
                        <th>Sku</th>
                        {/* <th>picture</th> */}
                        <th>Name</th>
                        <th>Price</th>
                        {/* <th>stock</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        orders.map((item) =>
                            <tr key={item}>
                                {/* <td key="{item.id}">{item.id}</td> */}
                                <td key="{item.sku}">{item.sku}</td>
                                {/* <td key="{item.picture}">
                                    <Image src={ URL + '/pub/media/catalog/product' + item.media_gallery_entries[0].file} rounded responsive />
                                </td> */}
                                <td key="{item.name}">{item.name}</td>
                                <td key="{item.price}">€{parseFloat(item.price).toFixed(2)}</td>
                                {/* <td key="{item.id}">{item.stock.qty}</td> */}
                                <td key="{item.length + item.id }">
                                    <Link to={`/products/detail/${item.sku}`} variant="primary">View more</Link></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}   