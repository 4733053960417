import React from 'react'
import { Navbar, Nav,NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const NavigationMenu = () => {

    //

    //

    return (
        <Navbar sticky="top" bg="light" expand="lg">
            <LinkContainer to="/">
                <Navbar.Brand>Home</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <NavDropdown title="Orders" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/orders">Overview</NavDropdown.Item>
                        <NavDropdown.Item href="/orders/unfinished">Orders to do</NavDropdown.Item>
                        <NavDropdown.Item href="/orders/refunded">Orders refunded</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/report/orders">Report</NavDropdown.Item>
                    </NavDropdown>
                    <LinkContainer to="/products">
                        <Nav.Link>Products</Nav.Link>
                    </LinkContainer>
                    {/* <NavDropdown title="Products" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/products">Overview</NavDropdown.Item> */}
                        {/* <NavDropdown.Item href="/product/add">Add new product</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/product/report">Report</NavDropdown.Item> */}
                    {/* </NavDropdown> */}
                    <LinkContainer to="/customers">
                        <Nav.Link>Customers</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/notifications">
                        <Nav.Link>Notifications</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/logout">
                        <Nav.Link>Log out</Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavigationMenu
