import * as axios from "axios";
import Api from "../Reusabilities/Api";

export default class DashBordApi extends Api {


    init = () => {

        let headers = {
            Accept: "application/json",
        };

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 28000,
            headers: headers,
        });

        return this.client;
    };

    getOrderslist = async ({ URL, token }) => {
        return await this.init().post('/orders', {
            URL, token
        })
    };


    getYearlyRevenue = async ({ URL, token, date }) => {
        console.log('api called');
        return await this.init().post(`/yearly/revenue/${date}`, {
            URL, token, date
        })
    };

    getQuartersRevenue = async ({ URL, token, date }) => {
        console.log('api called');
        return await this.init().post(`/quarters/revenue/${date}`, {
            URL, token, date
        })
    };

    getTodayRevenue = async ({ URL, token, date }) => {
        console.log('api called');

        const dateToday = new Date();
        const offsetToday = dateToday.getTimezoneOffset();
        const dateNowConverted = new Date(dateToday.getTime() - (offsetToday * 60 * 1000));

        console.log('date ');
        return await this.init().post(`/today/revenue/${dateNowConverted}`, {
            URL, token, dateNowConverted
        })
    };

    getAllRevenue = async ({ URL, token, date }) => {
        console.log('api called');

        return await this.init().post(`/all/revenue/${date}`, {
            URL, token, date
        })
    };

    getSelectedDayRevenue = async ({ URL, token, date }) => {
        console.log('api called');


        console.log('date ');
        return await this.init().post(`/selectedDay/revenue/${date}`, {
            URL, token, date
        })
    };

    getTransactions = async ({ URL, token, search }) => {
        //how many transactions are made in a particular period
    };

    getNumberOfOrdersMadeToday = async ({ URL, token, date }) => {
        console.log('api called');


        const dateToday = new Date();
        const offsetToday = dateToday.getTimezoneOffset();
        const dateNowConverted = new Date(dateToday.getTime() - (offsetToday * 60 * 1000));

        console.log('date ' + dateNowConverted);
        return await this.init().post(`/orders/date/${dateNowConverted}`, {
            URL, token, dateNowConverted
        })
    };


    getNumberOfCustomersSignedIn = async ({ URL, token, search }) => {
        //show how many customers are signed in on this
    };

    getNumberOfProductsSoldOnDate = async ({ URL, token, search }) => {
        //show how many customers are signed in on this
    };

}