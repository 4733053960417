import * as axios from "axios";
import Api from "../Reusabilities/Api";

export default class OrderApi extends Api {


  init = () => {

    let headers = {
      Accept: "application/json",
    };

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 28000,
      headers: headers,
    });

    return this.client;
  };

  getOrderslist = async ({ URL, token }) => {
    return await this.init().post('/orders', {
      URL, token
    })
  };

  getUnfinishedOrderslist = async ({ URL, token }) => {
    return await this.init().post('/orders/unfinished', {
      URL, token
    })
  };

  getRefundedProductslist = async ({ URL, token }) => {
    return await this.init().post('/refunds', {
      URL, token
    })
  };
  getNumberOfOrdersMadeToday = async ({ URL, token }) => {
    const date = new Date();
    const offsetToday = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offsetToday * 60 * 1000));

    console.log('date ', date);
    return await this.init().post(`/orders/date/${date}`, {
      URL, token, date
    })
  };

  getOrdersFilteredOnSpeceficDate = async ({ URL, token, date }) => {
    console.log('date ', date);
    return await this.init().post(`/orders/specific/date/${date}`, {
      URL, token, date
    })
  };

  getOrderDetail = async ({ URL, token, id }) => {
    return await this.init().post(`/orders/${id}`, {
      URL, token, id
    })
  };

  getSearchedOrderByOrderNumber = async ({ URL, token, search }) => {
    return await this.init().post(`/orders/order/${search}`, {
      URL, token
    })
  };

  searchOrder = async ({ URL, token, search }) => {
    return await this.init().post('/orderSearch', {
      URL, token, search
    })
  };

  createPush = async ({ URL, token, body, headers }) => {
    return await this.init().post('/push', {
      URL, token, body, headers
    })
  };

}