import React from 'react';
import ReactDOM from 'react-dom';
//old
import 'bootstrap/dist/css/bootstrap.css';
//new
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import App from './App';

//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import workerDev from './worker-dev'


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
workerDev();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
