import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Form, Container, Row, } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { notifications } from "../../utils/notifications";
//import Checkbox from '../Checkbox';
import TimePicker from 'react-time-picker';
import NotificationApi from '../NotificationApi';

export default function Notifications() {

  const [magentoOffline, setMagentoOffline] = useState("");

  const [data, setData] = useState([])
  const [orders, setOrders] = useState([])

  const api = new NotificationApi();

  const [timeOrder, setOnChangeTimeOrder] = useState(localStorage.getItem('timeNotificationMultipleOrders'));
  const [timeDailyRevenue, setOnChangeTimeDailyRevenue] = useState(localStorage.getItem('timeNotificationDailyRevenue'));

  var URL = localStorage.getItem('URL');
  var token = localStorage.getItem('token');



  const { id } = useParams();

  


  const [checkedMagentoOffline, setCheckedMagentoOffline] = React.useState(false);
  const [checkedReceiveOneOrderNotification, setCheckedReceiveOneOrderNotification] = React.useState(false);
  const [checkedReceiveMultipleOrderNotification, setCheckedReceiveMultipleOrderNotification] = React.useState(false);
  const [checkedDailyRevenueNotification, setDailyRevenueNotification] = React.useState(false);

  function refreshPage() {
    //sets magento offline correct:
    if(timeOrder != null){
      localStorage.setItem('timeNotificationMultipleOrders', timeOrder);
    }

    if(timeDailyRevenue != null){
      localStorage.setItem('timeNotificationDailyRevenue', timeDailyRevenue);
    }
    // const time = timeOrder;
    // console.log(timeOrder);
    // console.log(timeDailyRevenue);
    // //time today
    // console.log(new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false }));
    // //
    // localStorage.setItem('timeNotificationMultipleOrders', timeOrder);
    // localStorage.setItem('timeNotificationDailyRevenue', timeDailyRevenue);
    
    window.location.reload(false);
  }

  const handleMagentoOfflineChange = () => {
    setCheckedMagentoOffline(!checkedMagentoOffline);
    if (checkedMagentoOffline) {
      console.log(' Magento notification NOT checked');
      localStorage.setItem('magentoOfflineNotification', 'false');
    } else {
      console.log(' Magento notification checked');
      localStorage.setItem('magentoOfflineNotification', 'true');
    }
  };

  const receiveOneOrderNotification = () => {
    setCheckedReceiveOneOrderNotification(!checkedReceiveOneOrderNotification);
    if (checkedReceiveOneOrderNotification) {
      localStorage.setItem('receiveOneOrderNotification', 'false');
    } else {
      setCheckedReceiveMultipleOrderNotification(false);
      localStorage.setItem('receiveOneOrderNotification', 'true');
      localStorage.setItem('receiveMultipleOrderNotification', 'false');
    }
  };

  const receiveMultipleOrderNotification = () => {
    setCheckedReceiveMultipleOrderNotification(!checkedReceiveMultipleOrderNotification);
    if (checkedReceiveMultipleOrderNotification) {
      localStorage.setItem('receiveMultipleOrderNotification', 'false');
    } else {
      setCheckedReceiveOneOrderNotification(false);
      localStorage.setItem('receiveMultipleOrderNotification', 'true');
      localStorage.setItem('receiveOneOrderNotification', 'false');
    }

  };

  const receiveDailyRevenueNotification = () => {
    setDailyRevenueNotification(!checkedDailyRevenueNotification);
    if (checkedDailyRevenueNotification) {
      localStorage.setItem('receiveDailyRevenueNotification', 'false');
    } else {
      localStorage.setItem('receiveDailyRevenueNotification', 'true');
    }
  };

  const timeForMultipleNotifications = () => {
    // setCheckedReceiveMultipleOrderNotification(!checkedReceiveMultipleOrderNotification);
    // if (checkedReceiveMultipleOrderNotification) {
    //   localStorage.setItem('receiveMultipleOrderNotification', 'false');
    // } else {
    //   localStorage.setItem('receiveMultipleOrderNotification', 'true');
    // }
    //setOnChangeTimeOrder = 
    // console.log(timeOrder);
  };



  useEffect(() => {



    if (localStorage.getItem('magentoOfflineNotification') == 'true') {
      setCheckedMagentoOffline(!checkedMagentoOffline);
    } else {
      setCheckedMagentoOffline(checkedMagentoOffline);
    }

    if (localStorage.getItem('receiveOneOrderNotification') == 'true') {
      setCheckedReceiveOneOrderNotification(!checkedReceiveOneOrderNotification);
    } else {
      setCheckedReceiveOneOrderNotification(checkedReceiveOneOrderNotification);
    }

    if (localStorage.getItem('receiveMultipleOrderNotification') == 'true') {
      setCheckedReceiveMultipleOrderNotification(!checkedReceiveMultipleOrderNotification);
    } else {
      setCheckedReceiveMultipleOrderNotification(checkedReceiveMultipleOrderNotification);
    }

    if (localStorage.getItem('receiveDailyRevenueNotification') == 'true') {
      setDailyRevenueNotification(!checkedDailyRevenueNotification);
    } else {
      setDailyRevenueNotification(checkedDailyRevenueNotification);
    }

    // api.getOrderDetail({ URL, token, id})
    //     .then(response => {

    //         if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

    //         }
    //         else {
    //             localStorage.setItem('orderDetail', JSON.stringify(response.data));
    //             setOrders(response.data.items);
    //             setData(response.data);
    //         }
    //     })
    //     .catch(err => {
    //         setData(JSON.parse(localStorage.getItem('orderDetail')));


    //     })
    // eslint-disable-next-line.

  }, [])





  return (
    <div style={{ backgroundColor: "#D6E6E6" }}>
      <br /> 
      <h4>Notifications</h4>
      <hr color="#E4A302"></hr>


      <Container fluid style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <div className="App">
          <label>Select the notifications you want to receive:</label>
          <br />
          <label>
            <input
              type="checkbox"
              checked={checkedMagentoOffline}
              onChange={handleMagentoOfflineChange}
            />
            &nbsp;Receive webshop offline notification
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              checked={checkedReceiveOneOrderNotification}
              onChange={receiveOneOrderNotification}
            />
            &nbsp;Receive one new order notification
          </label>

          <br />
          <label>
            <input
              type="checkbox"
              checked={checkedReceiveMultipleOrderNotification}
              onChange={receiveMultipleOrderNotification}
            />
            &nbsp;Receive multiple orders notification&nbsp;
              <br /> 
      <TimePicker
        onChange={setOnChangeTimeOrder}
        value={timeOrder}
      />
    <br />
      <br /> 
          </label>
          <label>
            <input
              type="checkbox"
              checked={checkedDailyRevenueNotification}
              onChange={receiveDailyRevenueNotification}
            />
            &nbsp;Receive revenue of today notification&nbsp;
            <br />
      <TimePicker
        onChange={setOnChangeTimeDailyRevenue} 
        value={timeDailyRevenue}
      />
    </label>
    <br />
          {/* <br />
          <label>
            <input
              type="checkbox"
            // checked={checkedReceiveOneOrderNotification}
            // onChange={receiveOneOrderNotification}
            />
            &nbsp;Receive daily revenue
          </label> */}

          <br />

          <Button id="saveNotification" variant="btn btn-success" onClick={refreshPage}>Save notifications</Button>

          {/* <p>Is "My Value" checked? {checked.toString()}</p> */}
          {/* <div>
  <input style={{textAlign: 'left'}} 
    type="checkbox" 
    id="websiteOffline" 
    name="websiteOffline" 
    value="websiteOffline"
    onChange={this.handleCheckboxChange}
    />


</div> */}
          {/* <div className="websiteOffline">
    <input style={{textAlign: 'left'}} 
    type="checkbox" 
    id="websiteOffline" 
    name="websiteOffline" 
    value="websiteOffline"
    onChange={this.handleCheckboxChange}
    /> Receive notifications that your magento shop is offline
  </div>
  <br/>
  <div className="newOrderNotification">
    <input style={{textAlign: 'left'}} type="checkbox" id="newOrderNotification" name="newOrderNotification" value="newOrderNotification" /> Receive notifications that you have 1 new order
  </div>
  <br/>
  <div className="fiveNewOrdersNotification">
    <input style={{textAlign: 'left'}} type="checkbox" id="fiveNewOrdersNotification" name="fiveNewOrdersNotification" value="fiveNewOrdersNotification" /> Receive notifications that you have 5 new orders
  </div>
  <br/>
  <div className="outOfStockNotification">
    <input style={{textAlign: 'left'}} type="checkbox" id="outOfStockNotification" name="outOfStockNotification" value="outOfStockNotification" /> Receive notifications that your product is out of stock
  </div>*/}
        </div>
        {/* <Row >
    <Col style={{color:"#009199", textAlign: 'left'}}>Email</Col>
    <Col >{data.customer_email}</Col>
  </Row>
  <br/>
  <Row >
    <Col style={{color:"#009199", textAlign: 'left'}}>Purchase date</Col>
    <Col>{convertDate(data.created_at)}</Col>
  </Row>
  <br/>
  <Row >
    <Col style={{color:"#009199", textAlign: 'left'}}>Grand Total</Col>
    <Col>€{parseFloat(data.base_grand_total).toFixed(2)}</Col>
  </Row>
  <br/>
  <Row>
    <Col style={{color:"#009199", textAlign: 'left'}}>Status</Col>
    <Col>{data.status}</Col>
  </Row>
  <br/>
  <Row>
    <Col style={{color:"#009199", textAlign: 'left'}}>Number of items</Col>
    <Col>{orders.length}</Col>
  </Row>
  <br/>
  <Row>
    <Col style={{color:"#009199", textAlign: 'left'}}>Website</Col>
    <Col>{mainWebsite(data.store_name)}</Col>
  </Row>
  <br/>
<Row>
    <Col style={{color:"#009199", textAlign: 'left'}}>Store</Col>
    <Col>{store(data.store_name)}</Col>
  </Row>
  <br/>
<Row>
    <Col style={{color:"#009199", textAlign: 'left'}}>Store View</Col>
    <Col>{storeView(data.store_name)}</Col>
  </Row> */}
      </Container>
      <br />
      <br />
      {/* <Table responsive="sm"> */}
      {/* <thead> */}
      {/* <tr> */}

      {/* <th>ID</th>
                        <th>Email</th>
                        <th>Date</th>
                        <th>Grand total</th>
                        <th>Status</th>
                        <th></th> */}
      {/* </tr> */}
      {/* </thead> */}
      {/* <tbody> */}
      {/* { */}

      {/* <tr key="{data.entity_id}">
                                <td key="{data.entity_id}">{data.entity_id}</td>
                                <td key="{data.customer_email}">{data.customer_email}</td>
                                <td key="{data.updated_at}">{data.updated_at}</td>
                                <td key="{data.base_grand_total}">{data.base_grand_total}</td>
                                <td key="{data.status}">{data.status}</td>
                            </tr> */}

      {/* } */}
      {/* </tbody> */}
      {/* </Table> */}
      {/* <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Search on order id" />
                <br />
                <button style={{float: 'left'}} type="button" class="btn btn-primary">Search</button>
            </Form.Group>
            <br />
            <br />
            <h2 style={{float: 'left'}} class="text-info">These are the items in your order</h2>
            <br /> */}

    </div>
  )
}