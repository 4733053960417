import * as axios from "axios";
import Api from "../Reusabilities/Api";

export default class CustomerApi extends Api {
 
    
  init = () => {
  
    let headers = {
      Accept: "application/json",
    };

    this.client = axios.create({    
        baseURL: this.api_url,
        timeout: 28000,
        headers: headers,
    });

    return this.client;
  };

  getCustomerlist = async ({URL,token}) => {
     return await this.init().post('/customers', {
        URL,token
    })
  };

  searchCustomer = async ({URL,token,search}) => {
     return await this.init().post('/customerSearch', {
        URL,token,search
    })
  };

  addCustomer = async ({
    URL,token,
    firstname,
    lastname,
    email,
    password}) => {
     return await this.init().post('/addCustomer', {
        URL,
        token,
        firstname,
        lastname,
        email,
        password
    })
  };

}