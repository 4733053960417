import React from 'react';
export default function Logout()
{
   // var currentURL = (document.URL); // returns http://myplace.com/abcd
 //   var part = currentURL.split("/")[3];
    localStorage.removeItem('token');
    localStorage.removeItem('URL');
    window.location.href ='/';

    return(
        
        <div>
            <h1>Logging out...</h1>
        </div>
    )
}   