import WorkerApi from './WorkerApi'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import React from 'react';
// import "./styles.css";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

export default function workerDev() {

    const api = new WorkerApi();



    // 2 old lines, comment the other code out of it doesn't work in production and uncomment this.
    // let swUrl = `${process.env.PUBLIC_URL}/worker.js`;
    // navigator.serviceWorker.register(swUrl);

    //     if ('serviceWorker' in navigator) {
    //         window.addEventListener('load', function () {
    //             navigator.serviceWorker.register(swUrl);
    //         });
    //    }

    // async function askUserPermission() {
    //     return await Notification.requestPermission();
    //   }
    //   askUserPermission();

    //   async function createNotificationSubscription() {
    //     //wait for service worker installation to be ready
    //     const serviceWorker = await navigator.serviceWorker.ready;
    //     // subscribe and return the subscription
    //     return await serviceWorker.pushManager.subscribe({
    //       userVisibleOnly: true,
    //       applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    //       //applicationServerKey: pushServerPublicKey
    //     });
    //   }
    //   createNotificationSubscription();

    function giveDataToServiceWorkerForBackground() {

        var apiForServiceWorker = '';
        if (api.hasOwnProperty("api_url")) {
            apiForServiceWorker = api.api_url
        }

        const channel4Broadcast = new BroadcastChannel('URL');
        channel4Broadcast.postMessage({
            key: localStorage.getItem('URL'), token: localStorage.getItem('token'),
            lastorderDate: localStorage.getItem('lastorderDate'),
            givesNewOrder: newOrder,
            apiRoute: apiForServiceWorker,
            magentoStatus: localStorage.getItem('magentoOfflineNotification'),
            oneOrder: localStorage.getItem('receiveOneOrderNotification'),
            multipleOrders: localStorage.getItem('receiveMultipleOrderNotification'),
            timeNotificationMultipleOrders: localStorage.getItem('timeNotificationMultipleOrders'),
            dailyRevenue: localStorage.getItem('receiveDailyRevenueNotification'),
            dailyRevenueTime: localStorage.getItem('timeNotificationDailyRevenue')

        });
    }
    giveDataToServiceWorkerForBackground();

    var URL = localStorage.getItem('URL');
    var newOrder = '';

    var token = localStorage.getItem('token');

    //publicVapidKey is needed for the notifications
    const publicVapidKey = 'BCDW4Y9-IorpEdBog1SQUDWdkYGwUlj3cYMhQZk4fQHorSzIeDMBYfGWFRmx1ljoix6u1Q_0x7-4tv4QrAeA7gQ';

    const urlBase64ToUint8Array = (base64String) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    let swUrl = `${process.env.PUBLIC_URL}/worker.js`;



    function initSW() {
        if (!"serviceWorker" in navigator) {
            //service worker isn't supported
            return;
        }

        //don't use it here if you use service worker
        //for other stuff.
        if (!"PushManager" in window) {
            //push isn't supported
            return;
        }

        //register the service worker
        navigator.serviceWorker.register(swUrl)
            .then(() => {
                console.log('serviceWorker installed!')
                initPush();
            })
            .catch((err) => {
                console.log(err)
            });
    }
    initSW();

    function initPush() {
        if (!navigator.serviceWorker.ready) {
            return;
        }

        new Promise(function (resolve, reject) {
            const permissionResult = Notification.requestPermission(function (result) {
                resolve(result);
            });

            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        })
            .then((permissionResult) => {
                if (permissionResult !== 'granted') {
                    localStorage.setItem('subscription', false);
                } else {
                    subscribeUser();
                }

            });
    }


    function subscribeUser() {
        navigator.serviceWorker.ready
            .then((registration) => {
                const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(
                        publicVapidKey
                    )
                };

                return registration.pushManager.subscribe(subscribeOptions);
            })
            .then((pushSubscription) => {
                console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
                localStorage.setItem('subscription', true);
                //storePushSubscription(pushSubscription);
            });
    }

    function storePushSubscription(pushSubscription) {
        var token = localStorage.getItem('token');
        var URL = localStorage.getItem('URL');
        var body = pushSubscription;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': token
        }
        api.createPush({ URL, token, body, headers })
            .then(response => {
            })
            .catch(err => {
            })
    }
}

