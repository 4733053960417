import React, { useState, useEffect } from 'react';
import {Card, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OrderApi from '../../Orders/OrderApi';
import WorkerApi from '../../WorkerApi';
import DashbordApi from '../DashBordApi';
import DatePicker from 'react-datepicker';
import { store } from 'react-notifications-component';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { Notyf } from "notyf";
import "notyf/notyf.min.css";

export default function Home()
{

    const [data, setData] = useState([])

    //revenues
    const [allRevenue, setAllRevenue] = useState([])
    const [yearlyRevenue, setYearlyRevenue] = useState([0])
    const [todayRevenue, setTodayRevenue] = useState([0])
    const [selectedDayRevenue, setSelectedDayRevenue] = useState([0])
    const [QuartersRevenue, setQuartersRevenue] = useState([0])

    const [search, setS] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredOrders, setfilteredOrders] = useState([])
    const [loading, setLoading] = React.useState(false);

    var URL = localStorage.getItem('URL');
    var token = localStorage.getItem('token');
    const api = new OrderApi();
    const dashBordApi = new DashbordApi();
    const workerApi = new WorkerApi();
    var count = 0;
    var notificationRevenue = 0;

    useEffect(() => {
      console.log('api called'); 

      const date = new Date();
      // if(localStorage.getItem('subscription') == 'true'){
      //   dashBordApi.getTodayRevenue({ URL, token, date})
      //   .then(response => {

      //       if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

      //       }
      //       else {
      //           setTodayRevenue(response.data)
      //             notificationRevenue = response.data;
      //           //   const notyf = new Notyf({
      //           //     position: {
      //           //         x: 'center',
      //           //         y: 'top',
      //           //     },
      //           //     types: [
      //           //         {
      //           //             type: "loading",
      //           //             background: "green",
      //           //             dismissible: true,
      //           //         },
      //           //         //other custom toasts if any
      //           //     ],
      //           // });
      //           // const notification = notyf.success({
      //           //     message: 'This is your revenue: €'+notificationRevenue,
      //           //     duration: 9000
      //           //   });
      //           }
      //   })
      //   .catch(err => {
      //   })
      // }
      dashBordApi.getNumberOfOrdersMadeToday({ URL, token, date})
          .then(response => {

              if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

              }
              else {
                  localStorage.setItem('ordersMadeToday', JSON.stringify(response.data));
                  setData(response.data);
                  count = response.data.length;
                  console.log('items '+ response.data.length)
                  console.log('items '+ count )
                  //setfilteredOrders(response.data);
                  setLoading(true);


                  
              }
          })
          .catch(err => {
              setData(JSON.parse(localStorage.getItem('ordersMadeToday')));
          })
      // eslint-disable-next-line
     
      
  

  },  [])
  

  

  function getYearlyRevenue(date){
    dashBordApi.getYearlyRevenue({ URL, token, date})
          .then(response => {

              if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

              }
              else {
                  localStorage.setItem('yearlyRevenue', JSON.stringify(response.data.items));
                  setYearlyRevenue(response.data);
                  //setfilteredOrders(response.data);
                  setLoading(true);
              }
          })
          .catch(err => {
            setYearlyRevenue(JSON.parse(localStorage.getItem('yearlyRevenue')));
          })
  }

  function getAllRevenue(date){
    dashBordApi.getAllRevenue({ URL, token, date})
          .then(response => {

              if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

              }
              else {
                  localStorage.setItem('allRevenue', JSON.stringify(response.data));
                  setAllRevenue(response.data);
                  console.log('REVENUE TEST ',allRevenue['yearly']);
                  
                  setLoading(true);
              }
          })
          .catch(err => {
            setAllRevenue(JSON.parse(localStorage.getItem('allRevenue')));
          })
  }

  function getTodayRevenue(date){
    dashBordApi.getTodayRevenue({ URL, token, date})
          .then(response => {

              if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

              }
              else {
                  localStorage.setItem('todayRevenue', JSON.stringify(response.data));
                  setTodayRevenue(response.data);
                  setLoading(true);
              }
          })
          .catch(err => {
            setTodayRevenue(JSON.parse(localStorage.getItem('todayRevenue')));
          })
  }

  function getSelectedDayRevenue(date){
    dashBordApi.getSelectedDayRevenue({ URL, token, date})
          .then(response => {

              if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

              }
              else {
                  localStorage.setItem('selectedDayRevenue', JSON.stringify(response.data));
                  setSelectedDayRevenue(response.data);
                  setLoading(true);
              }
          })
          .catch(err => {
            setSelectedDayRevenue(JSON.parse(localStorage.getItem('selectedDayRevenue')));
          })
  }

  function getQuartersRevenue(date){
    dashBordApi.getQuartersRevenue({ URL, token, date})
          .then(response => {

              if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

              }
              else {
                  localStorage.setItem('QuartersRevenue', JSON.stringify(response.data));
                  setQuartersRevenue(response.data);
                  setLoading(true);
              }
          })
          .catch(err => {
            setQuartersRevenue(JSON.parse(localStorage.getItem('QuartersRevenue')));
          })
  }

  
    

  function test(){
    console.log('test');
    workerApi.makePushNotification({ URL, token})
          .then(response => {

              if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

              }
              else {
                console.log(response);
                store.addNotification({
                  title: response.data,
                  message: "teodosii@react-notifications-component",
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: true
                  }
                });
                  // localStorage.setItem('allRevenue', JSON.stringify(response.data.items));
                  // setAllRevenue(response.data);
                  // console.log('REVENUE TEST ',allRevenue['yearly']);
                  
                  // setLoading(true);
              }
          })
          .catch(err => {
            //setAllRevenue(JSON.parse(localStorage.getItem('allRevenue')));
          })
  }

  function showAllRevenuesOnDate() {
    //convert date to y:m:d
    const offset = selectedDate.getTimezoneOffset(); 
    const convertedDate = new Date(selectedDate.getTime() - (offset*60*1000)); 
    const trueDate = convertedDate.toISOString().split('T')[0];

    console.log(trueDate);

    //call functions for revenue
    //getYearlyRevenue(trueDate);
    //getTodayRevenue(trueDate);
    getAllRevenue(trueDate);
    getSelectedDayRevenue(trueDate);
    //getQuartersRevenue(trueDate);
}
    return(        <>
        <div>
            <h1>Dashboard</h1>
        </div>

        <div>
      <DatePicker utcOffset={0} selected={selectedDate} onChange={date => setSelectedDate(date)}
      dateFormat='yyyy-MM-dd'  />
      <br />
      <br />
      <Button variant="btn btn-primary" onClick={() => showAllRevenuesOnDate()}>Select a date to get the revenues</Button>
      <br />
      <br />
  </div >
        <Card border="primary" >
          <Card.Header>Orders </Card.Header>
          <Card.Body>
            <Card.Title>{data.length} Orders made today</Card.Title>
            {/* <Card.Text>
            That is x more/less than yesterday or 5,400 euros total value as example

            </Card.Text> */}


            {/* <Table responsive="sm">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item) =>
                            <tr key="{item.entity_id}">
                                <td key="{item.entity_id}">{item.entity_id}</td>
                                <td key="{item.entity_id}">{data.length}</td>
                                
                            </tr>
                        )
                    }
                </tbody>
            </Table> */}
           
 <Link to="/orders"><Button variant="primary" block>See all orders</Button>
 </Link>
          </Card.Body>
        </Card>
        <br />
        
        {/* <Card border="secondary" >
          <Card.Header>Customers</Card.Header>
          <Card.Body>
            <Card.Title>2 new customers today</Card.Title>
            <Card.Text>
              Hier komen de nieuwe klanten van de dag
            </Card.Text>
            <Button variant="primary" block>See new customers</Button>
          </Card.Body>
        </Card>
        <br /> */}
        
     
        <Card border="success" >
          <Card.Header>Total Revenue of selected date</Card.Header>
          <Card.Body>
            <Card.Title>Yearly revenue</Card.Title>
            <Card.Text >
            € {allRevenue['yearly'] ? allRevenue['yearly'] : 0}
            </Card.Text>
            <Card.Title>Todays revenue</Card.Title>
            <Card.Text>
            € {allRevenue['day'] ? allRevenue['day'] : 0}
            </Card.Text>
            <Card.Title>Selected date revenue</Card.Title>
            <Card.Text>
            € {selectedDayRevenue ? selectedDayRevenue : 0}
            </Card.Text>
            <Card.Title>All quarters revenue</Card.Title>
            {/* <Table > */}
            <Card.Text>
            First quarter € {allRevenue['first_quarter'] ? allRevenue['first_quarter'] : 0}
            </Card.Text>
            <Card.Text>
            Second quarter € {allRevenue['second_quarter'] ? allRevenue['second_quarter'] : 0}
            </Card.Text>
            <Card.Text>
            Third quarter € {allRevenue['third_quarter'] ? allRevenue['third_quarter'] : 0}
            </Card.Text>
            <Card.Text>
            Last quarter € {allRevenue['last_quarter'] ? allRevenue['last_quarter'] : 0}
            </Card.Text>
                    
                {/* <Tbody>
                    {
                
                       QuartersRevenue.map((item, index) =>
                       
                            <h6>Quarter {item, index + 1}: € {item}</h6>
                        )
                    }
                </Tbody> */}
            {/* </Table> */}
          </Card.Body>
        </Card>
        <br />
      
        {/* <Card border="danger" >
          <Card.Header>Header</Card.Header>
          <Card.Body>
            <Card.Title>Danger Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the bulk
              of the card's content.
            </Card.Text>
          </Card.Body>
        </Card>
        <br /> */}
      
        {/* <Card border="warning" >
          <Card.Header>Header</Card.Header>
          <Card.Body>
            <Card.Title>Warning Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the bulk
              of the card's content.
            </Card.Text>
          </Card.Body>
        </Card>
        <br /> */}
      
        {/* <Card border="info" >
          <Card.Header>Header</Card.Header>
          <Card.Body>
            <Card.Title>Info Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the bulk
              of the card's content.
            </Card.Text>
          </Card.Body>
        </Card>
        <br /> */}
      
        {/* <Card border="dark" >
          <Card.Header>Header</Card.Header>
          <Card.Body>
            <Card.Title>Dark Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the bulk
              of the card's content.
            </Card.Text>
          </Card.Body>
        </Card>
        <br /> */}
      
        {/* <Card border="light" >
          <Card.Header>Header</Card.Header>
          <Card.Body>
            <Card.Title>Light Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the bulk
              of the card's content.
            </Card.Text>
          </Card.Body>
        </Card> */}
      </>
    );
}   