export const notifications = [
    {
      name: "websiteOffline"
    },
    {
      name: "oneNewOrder"
    },
    {
      name: "fiveNewOrders"
    },
    {
      name: "outOfStock"
    },
    
  ];