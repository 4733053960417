import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Table,Button} from 'react-bootstrap'
import OrderApi from '../OrderApi';
import { Link } from 'react-router-dom';
import Pagination from '../../Components/Pagination';

export default function RefundedOrders() {
    const [search, setS] = useState('');

    var URL = localStorage.getItem('URL');
    var token = localStorage.getItem('token');
    const api = new OrderApi();
    const [data, setData] = useState([])

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);

    // function updateInput(e) {

    //     e.preventDefault();
    //     if (!search) {
    //         setData(JSON.parse(localStorage.getItem('ProductsRefunded')));
    //         return true;
    //     }
    //     api.searchOrder({ URL, token, search })
    //         .then(response => {
    //             setData(response.data);
    //         })
    //         .catch(err => {
    //             console.log('no ProductsRefunded found');
    //         })

    // }

    useEffect(() => {
        api.getRefundedProductslist({ URL, token })
            .then(response => {

                if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

                }
                else {
                    localStorage.setItem('ProductsRefunded', JSON.stringify(response.data));
                    setData(response.data);
                    
                }
            })
            .catch(err => {
                setData(JSON.parse(localStorage.getItem('ProductsRefunded')));
            })
        // eslint-disable-next-line
    }, [])

    // get pagination
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = data.slice(indexOfFirstData, indexOfLastData);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    
    return (
        <div>
            
            <br></br>
            
            <h3>Refunded products</h3>
            <table class="table table-striped"  responsive="sm">
                <thead>
                    <tr>
                        {/* <th>ID</th> */}
                        {/* <th>Sku</th> */}
                        <th>Name</th>
                        <th>Date</th>
                        <th>Price</th>
                        {/* <th>Quantity</th> */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                    currentData.map((item) =>
                            <tr key="{item.item_id}">
                                {/* <td key="{item.item_id + created_at}">{item.item_id}</td> */}
                                {/* <td key="{item.sku}">{item.sku}</td> */}
                                <td key="{item.name}">{item.name}</td>
                                <td key="{item.created_at}">{new Date(item.created_at).toISOString().split('T')[0]}</td>
                                <td key="{item.price}">€{parseFloat(item.price).toFixed(2)}</td>
                                {/* <td key="{item.qty_ordered}">{item.qty_ordered}</td> */}
                                <td key="{item.items.length + item_id }">
                                    <Link to={`/orders/detail/${item.order_id}`} variant="primary">View order</Link></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <Pagination
        dataPerPage={dataPerPage}
        totalData={data.length}
        paginate={paginate}
      />
        </div>
    )
}   