import * as axios from "axios";
import Api from "../Reusabilities/Api";

export default class NotificationApi extends Api {
 
    
  init = () => {
  
    let headers = {
      Accept: "application/json",
    };

    this.client = axios.create({    
        baseURL: this.api_url,
        timeout: 28000,
        headers: headers,
    });

    return this.client;
  };


  getTimeNotificationsMultipleOrders = async ({ URL, token}) => {
  
  return await this.init().post(`/notifications/orders`, {
      URL,token
  })
};



}