import * as axios from "axios";

export default class Api {
   
    constructor() {
    this.api_url = process.env.REACT_APP_Middleware;
  }

  init = () => {
  
    let headers = {
      Accept: "application/json",
    };

    this.client = axios.create({    
        baseURL: this.api_url,
        timeout: 30000,
        headers: headers,
    });

    
    return this.client;
  };

}