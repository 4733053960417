import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button,Form, Container, Row, Col} from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function WebshopOffline() {
    useEffect(() => {
        
    }, [])
    return (
        <div style={{backgroundColor: "#D6E6E6"}}>
            <h1>Your magento webshop is offline</h1>
        </div>
    )
}   