import React from 'react';
//import logo from './logo.svg';
import { Container, Row, Col } from 'react-bootstrap'

import './App.css';
import Orders from './Orders/Pages/Orders'
import UnfinishedOrders from './Orders/Pages/UnfinishedOrders'
import RefundedOrders from './Orders/Pages/RefundedOrders'
import OrderDetail from './Orders/Pages/OrderDetail';
import Products from './Products/Pages/ProductsPage'
import ProductDetail from './Products/Pages/ProductDetail'
import Customers from './Customers/Pages/CustomersPage'
import Notifications from './Notifications/Pages/Notifications'
import Login from './Home/Pages/LoginPage.jsx'
import Logout from './Home/Pages/LogoutPage'
import Home from './Home/Pages/HomePage'
import WebshopOffline from './Home/WebshopOfflinePage'
import NavigationMenu from './Components/NavigationMenu'
import OfflineStatus from './Components/OfflineStatus'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'


function App() {
  let unix_timestamp = parseInt(localStorage.getItem('timestamp'));
  var date = new Date(unix_timestamp);
  var hoursDiff = (new Date().getTime() - date.getTime());
  hoursDiff = Math.ceil(hoursDiff / (1000 * 3600));

  if (hoursDiff < 0) hoursDiff += 24;
  if (hoursDiff > 3) {
    localStorage.removeItem('timestamp');
    localStorage.removeItem('token');
  }
  function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
  }
  console.log('support:? ' + isPushNotificationSupported());

  if (localStorage.getItem('token') === 'undefined' || localStorage.getItem('token') === null) {
    return (

      <Container>
        <Row>
          <Col>
            <Login />
          </Col>
        </Row>
      </Container>)
  }

  return (

    <Container>
      <Row>
        <Col>
          <div className="App">
            <ReactNotification />
            <Router>
              <NavigationMenu />
              <OfflineStatus />
              <Switch>
                <Route path="/orders/detail/:id" component={OrderDetail}></Route>
                <Route path="/orders/unfinished" component={UnfinishedOrders}></Route>
                <Route path="/orders/refunded" component={RefundedOrders}></Route>
                <Route path="/orders" component={Orders}></Route>
                <Route path="/products/detail/:id" component={ProductDetail}></Route>
                <Route path="/products" component={Products}></Route>
                <Route path="/customers" component={Customers}></Route>
                <Route path="/notifications" component={Notifications}></Route>
                <Route path="/login" component={Login}></Route>
                <Route path="/webshop/offline" component={WebshopOffline}></Route>
                <Route path="/logout" component={Logout}></Route>

                <Route path="/" component={Home}></Route>
              </Switch>
            </Router>


          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
