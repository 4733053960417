import * as axios from "axios";
import Api from "./Reusabilities/Api";

export default class WorkedApi extends Api {


  init = () => {

    let headers = {
      Accept: "application/json",
    };

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 28000,
      headers: headers,
    });

    return this.client;
  };

  createPush = async ({ URL, token, body, headers }) => {
    return await this.init().post('/push', {
      URL, token, body, headers
    })
  };

  makePushNotification = async ({ URL, token }) => {
    return await this.init().get('/push', {
      URL, token,
    })
  };

  getLastOrder = async ({ URL, token }) => {
    return await this.init().post('/orders/last', {
      URL, token
    })
  };

}