import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Table,Button} from 'react-bootstrap'
import OrderApi from '../OrderApi';
import { Link } from 'react-router-dom';
import Pagination from '../../Components/Pagination';
import { Accordion, Card,InputGroup, Form } from 'react-bootstrap'

export default function Orders() {
    const [search, setS] = useState('');

    var URL = localStorage.getItem('URL');
    var token = localStorage.getItem('token');
    const api = new OrderApi();
    const [data, setData] = useState([])

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);

    function updateInput(e) {

        e.preventDefault();
        if (!search) {
            setData(JSON.parse(localStorage.getItem('OrdersToDo')));
            return true;
        }
        api.getSearchedOrderByOrderNumber({ URL, token, search })
            .then(response => {
                setData(response.data);
            })
            .catch(err => {
                console.log('no orders to do found');
            })

    }

    useEffect(() => {


        api.getUnfinishedOrderslist({ URL, token })
            .then(response => {

                if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

                }
                else {
                    localStorage.setItem('OrdersToDo', JSON.stringify(response.data));
                    setData(response.data);
                }
            })
            .catch(err => {
                setData(JSON.parse(localStorage.getItem('OrdersToDo')));
            })
        // eslint-disable-next-line
    }, [])

    // get pagination
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = data.slice(indexOfFirstData, indexOfLastData);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    
    return (
        <div>
            <br />
            <InputGroup className="mb-3">

<Form.Control

    value={search}

    type="text"
    placeholder="Search order by order number"
    onChange={(e) => setS(e.target.value)}
/>
<InputGroup.Append>
    <Button variant="btn btn-success" onClick={updateInput}>Search</Button>
</InputGroup.Append>
</InputGroup>
            <h3>Pending orders</h3>
            <table class="table table-striped" responsive="sm">
                <thead>
                    <tr>
                        {/* <th>Order number</th> */}
                        {/* <th>Purchased date</th> */}
                        <th>Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                    currentData.map((item) =>
                            <tr key="{item.entity_id}">
                                {/* <td key="{item.increment_id}">{item.increment_id}</td> */}
                                {/* <td key="{item.created_at}">{item.created_at}</td> */}
                                <td key="{item.billing_address.firstname +' '+ item.billing_address.lastname  }">{item.billing_address.firstname +' '+ item.billing_address.lastname  }</td>
                                <td key="{item.subtotal}">€{parseFloat(item.base_grand_total).toFixed(2)}</td>
                
                                <td key="{item.items.length + item.entity_id }">{item.items.length}</td>
                                <td key="{item.items.length + item.entity_id }">
                                    <Link to={`/orders/detail/${item.entity_id}`} variant="primary">Order details</Link></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <br />
            <Pagination
        dataPerPage={dataPerPage}
        totalData={data.length}
        paginate={paginate}
      />
        </div>
    )
}   