import React, { useState, useEffect } from 'react';
import { Accordion, Card, Form, Button, InputGroup } from 'react-bootstrap'
import CustomerApi from "../CustomerApi";
import Test from "./AddCustomerForm";

export default function Customers() {
    const [search, setS] = useState('');

    var URL = localStorage.getItem('URL');
    var token = localStorage.getItem('token');
    const api = new CustomerApi();
    const [data, setData] = useState([])

    function updateInput(e) {

        e.preventDefault();
        if (!search) {
            setData(JSON.parse(localStorage.getItem('customers')));
            return true;
        }
        api.searchCustomer({ URL, token, search })
            .then(response => {
                setData(response.data);
            })
            .catch(err => {
                console.log('no customer found');
            })

    }

    useEffect(() => {


        api.getCustomerlist({ URL, token })
            .then(response => {

                if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {

                }
                else {
                    localStorage.setItem('customers', JSON.stringify(response.data));
                    setData(response.data);
                }
            })
            .catch(err => {
                setData(JSON.parse(localStorage.getItem('customers')));
            })
        // eslint-disable-next-line
    }, [])

    function displayData() {
        if (data.type === 'error') { return 'data not found. Search again. ' }
        else {
            return <Accordion>
                {
                    data.map((item) =>


                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                                    {item.firstname + ' ' + item.lastname}<br /> {item.email}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={item.id}>
                                <Card.Body>
                                    <b>Info: </b><br />
                                    Id: {item.id}  <br />
                                    Full name: {item.firstname + ' ' + item.lastname}  <br />
                                    Email: {item.email} <br /><br />

                                    <b>Report: </b><br />
                                    Amount of the products bought: 120 <br />
                                    Amount of the orders to finish the process: 2 <br /><br />
                                    <Button block variant="primary" onClick={
                                        () => { alert('on maintanance') }
                                    }>Edit this customer</Button>
                                    <br />
                                    <Button block variant="info" onClick={
                                        () => { alert('on maintanance') }
                                    }>View the bought products </Button>

<br />
                                    <Button block variant="secondary" onClick={
                                        () => { alert('on maintanance') }
                                    }>View the unfinished orders </Button>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                }

            </Accordion>
        }
    }


    return (
        <div>
            <h1>Customers component</h1>
            <InputGroup className="mb-3">

                <Form.Control

                    value={search}

                    type="text"
                    placeholder="Search customer by email or id"
                    onChange={(e) => setS(e.target.value)}
                />
                <InputGroup.Append>
                    <Button variant="primary" onClick={updateInput}>Search</Button>
                </InputGroup.Append>
            </InputGroup>
            <Test />
            <br /><br />
            {displayData()}

        </div>
    )
}   