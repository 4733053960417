import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button,Form, Dropdown, DropdownButton } from 'react-bootstrap'
import OrderApi from '../OrderApi';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Pagination from '../../Components/Pagination';
import { Calendar } from 'react-date-range';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import moment from "moment";
import { Accordion, Card,InputGroup } from 'react-bootstrap'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';


export default function Orders() {
    const [data, setData] = useState([])
    const [search, setS] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredOrders, setfilteredOrders] = useState([])
    const [loading, setLoading] = React.useState(false);

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);


    var URL = localStorage.getItem('URL');
    var token = localStorage.getItem('token');
    const api = new OrderApi();


    //filters:
    var complete = data.filter(e => e.status === "complete" || e.status === "completed" )
    var pending = data.filter(e => e.status === "pending")
    var refund = data.filter(e => e.status === "closed")
    var processing = data.filter(e => e.status === "processing")
    var removedFilter = JSON.parse(localStorage.getItem('orders'));

    function updateInput(e) {

        e.preventDefault();
        if (!search) {
            setData(JSON.parse(localStorage.getItem('orders')));
            return true;
        }
        api.getSearchedOrderByOrderNumber({ URL, token, search })
            .then(response => {
                setData(response.data);
                setfilteredOrders(response.data);
            })
            .catch(err => {
                console.log('no order found');
            })
    }
  
    useEffect(() => {
        api.getOrderslist({ URL, token })
            .then(response => {

                if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {
                    
                }
                else {
                    localStorage.setItem('orders', JSON.stringify(response.data));
                    setData(response.data);
                    setfilteredOrders(response.data);
                    setLoading(true);
                }
            })
            .catch(err => {
                setData(JSON.parse(localStorage.getItem('orders')));
                setfilteredOrders(JSON.parse(localStorage.getItem('orders')));
                setLoading(true);
            })
        // eslint-disable-next-line
    },  [])

    if (!loading) {
        return ("Loading... Please wait a few seconds")
      }
    
    function removeFiter() {
        setfilteredOrders(removedFilter);
    }
    function showComplete() {
        setfilteredOrders(complete)
    }
    
    function showPending() {
        setfilteredOrders(pending)
    }

    function showRefunded() {
        setfilteredOrders(refund)
    }

    function showProcessing() {
        setfilteredOrders(processing)
    }

    //this is the filter for date.
    function showAllOrdersOnDate() {
        //convert date to y:m:d
        const offset = selectedDate.getTimezoneOffset(); 
        const convertedDate = new Date(selectedDate.getTime() - (offset*60*1000)); 
        const date = convertedDate.toISOString().split('T')[0];
        //day today is not used but this is how you get the correct date from today.
        // const dateToday = new Date();
        // const offsetToday = dateToday.getTimezoneOffset(); 
        // const dateNowConverted = new Date(dateToday.getTime() - (offsetToday*60*1000)); 
        // console.log('date today ', dateNowConverted.toISOString().split('T')[0]); 
        console.log(date)
        api.getOrdersFilteredOnSpeceficDate({ URL, token, date })
            .then(response => {

                if (response.hasOwnProperty('message') && response.hasOwnProperty('type')) {
                    
                }
                else {
                    // localStorage.setItem('orders', JSON.stringify(response.data));
                    setData(response.data);
                    setfilteredOrders(response.data);
                    setLoading(true);
                }
            })
            .catch(err => {
                setData(JSON.parse(localStorage.getItem('orders')));
                setfilteredOrders(JSON.parse(localStorage.getItem('orders')));
                setLoading(true);
            })

        // setfilteredOrders(data.filter(e => new Date(e.created_at).toISOString().split('T')[0] 
        // === convertedDate.toISOString().split('T')[0]))
    }
    
    // get pagination
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = data.slice(indexOfFirstData, indexOfLastData);
    const currentFilteredData = filteredOrders.slice(indexOfFirstData, indexOfLastData);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    
    return (
        <div class="container-fluid" style={{backgroundColor: "#D6E6E6"}}>
            <br />
            <InputGroup className="mb-3">

<Form.Control

    value={search}

    type="text"
    placeholder="Search order by order number"
    onChange={(e) => setS(e.target.value)}
/>
<InputGroup.Append>
    <Button variant="btn btn-success" onClick={updateInput}>Search</Button>
</InputGroup.Append>
</InputGroup>
            <h3>Orders</h3>
            
            {/* <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Search on order id" />
                <br />
                <button style={{float: 'left'}} type="button" class="btn btn-primary">Search</button>
            </Form.Group>
            <br />
            <br /> */}
            <div class="row">
            <div class="col-md-4 col-lg-2" style={{float: 'left'}}  aria-label="...">
  {/* <button type="button" class="btn btn-primary" onClick={() => showComplete()}>Completed</button>
  &nbsp;
  <button type="button" class="btn btn-primary" onClick={() => showPending()}>Pending</button>
  &nbsp;
  <button type="button" class="btn btn-primary" onClick={() => showRefunded()}>Refunded</button>
  &nbsp;
  <button type="button" class="btn btn-primary" onClick={() => showProcessing()}>Processing</button>
  &nbsp;
  <br />
  <br />
  <button type="button" class="text-dark" onClick={() => removeFiter()}>Remove Filter</button> */}
  <DropdownButton style={{float: 'left'}} id="dropdown-basic-button" title="Filter">
  <Dropdown.Item onClick={() => showComplete()}>Completed</Dropdown.Item>
  <Dropdown.Item onClick={() => showPending()}>Pending</Dropdown.Item>
  <Dropdown.Item onClick={() => showRefunded()}>Refunded</Dropdown.Item>
  <Dropdown.Item onClick={() => showProcessing()}>Processing</Dropdown.Item>
  <Dropdown.Divider />
  <Dropdown.Item onClick={() => removeFiter()}>Remove filter</Dropdown.Item>
</DropdownButton>
  <br />
  <br />
  
</div>
</div>
  <div>
      <DatePicker utcOffset={0} selected={selectedDate} onChange={date => setSelectedDate(date)}
      dateFormat='yyyy-MM-dd'  />
      <br />
      <br />
      <Button variant="btn btn-primary" onClick={() => showAllOrdersOnDate()}>Submit</Button>
  </div >
  <br />
  <table class="table table-striped" responsive="sm">
                <thead>
                    <tr>
                        {/* <th>Order number</th> */}
                        {/* <th>Purchased date</th> */}
                        <th>Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                    currentData && currentFilteredData.map((item) =>
                            <tr key="{item.entity_id}">
                                {/* <td key="{item.increment_id}">{item.increment_id}</td> */}
                                {/* <td key="{item.created_at}">{item.created_at}</td> */}
                                <td key="{item.billing_address.firstname +' '+ item.billing_address.lastname  }">{item.billing_address.firstname +' '+ item.billing_address.lastname  }</td>
                                <td key="{item.subtotal}">€{parseFloat(item.base_grand_total).toFixed(2)}</td>
                
                                <td key="{item.items.length + item.entity_id }">{item.items.length}</td>
                                <td key="{item.items.length + item.entity_id }">
                                    <Link to={`/orders/detail/${item.entity_id}`} variant="primary">Order details</Link></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
           
            <br />
            <Pagination
        dataPerPage={dataPerPage}
        totalData={data.length}
        paginate={paginate}
      />
        </div>
    )
}   
